<section class="main-container bg-MoradoClaro">
	<div class="blog-container">
		<div
			class="xs:h-2-eb-30 sm:h-2-eb-50 md:h-2-eb-40 lg:h-2-eb-50 font-['Montserrat'] text-[50px] font-extrabold text-teal-900">
			BLOG
		</div>
		<div class="middle-container">
			<div class="cards-container">
				<a [routerLink]="posts[0].postUrl ? '/post/' + posts[0].postUrl : ''">
					<div class="card primary-card">
						<img class="image-primary rounded-[15px] object-cover" src="{{ posts[0].postImageUrl }}" alt="post image" />
						<div class="primary-text-field">
							<span class="primary-text xs:h-4-eb-20 sm:h-4-eb-20 lg:h-4-eb-25 font-['Montserrat'] text-teal-900">
								{{ posts[0].postTitle }}<br /><br />
							</span>
							<span class="sub-text line-clamp-4 font-['Poppins'] text-xl font-medium text-teal-900">
								{{ posts[0].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [routerLink]="posts[1].postUrl ? '/post/' + posts[1].postUrl : ''">
					<div class="card primary-card">
						<img class="image-primary rounded-[15px] object-cover" src="{{ posts[1].postImageUrl }}" alt="post image" />
						<div class="primary-text-field">
							<span
								class="truncate-text primary-text xs:h-4-eb-20 sm:h-4-eb-20 lg:h-4-eb-25 font-['Montserrat'] text-teal-900">
								{{ posts[1].postTitle }}<br /><br />
							</span>
							<span class="sub-text line-clamp-4 font-['Poppins'] text-xl font-medium text-teal-900">
								{{ posts[1].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [routerLink]="posts[2].postUrl ? '/post/' + posts[2].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="image-secondary rounded-[15px] object-cover"
							src="{{ posts[2].postImageUrl }}"
							alt="post image" />
						<div
							class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 font-['Montserrat'] text-teal-900">
							{{ posts[2].postTitle }}
						</div>
					</div>
				</a>
				<a [routerLink]="posts[3].postUrl ? '/post/' + posts[3].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="image-secondary rounded-[15px] object-cover"
							src="{{ posts[3].postImageUrl }}"
							alt="post image" />
						<div
							class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 font-['Montserrat'] text-teal-900">
							{{ posts[3].postTitle }}
						</div>
					</div>
				</a>
				<a [routerLink]="posts[4].postUrl ? '/post/' + posts[4].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="image-secondary rounded-[15px] object-cover"
							src="{{ posts[4].postImageUrl }}"
							alt="post image" />
						<div
							class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 font-['Montserrat'] text-teal-900">
							{{ posts[4].postTitle }}
						</div>
					</div>
				</a>
				<a [routerLink]="posts[5].postUrl ? '/post/' + posts[5].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="image-secondary rounded-[15px] object-cover"
							src="{{ posts[5].postImageUrl }}"
							alt="post image" />
						<div
							class="truncate-last-card secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 font-['Montserrat'] text-teal-900">
							{{ posts[5].postTitle }}
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>
<div class="flex justify-center pb-[4.438rem]">
	<button
		(click)="goToLink('/' + blogUrl.Base)"
		class="my-3 w-[8.75rem] gap-[0.625rem] rounded-[2.25rem] border-[0.1875rem] border-[#004751] px-4 py-2 font-montserrat text-xl font-extrabold text-[#004751] hover:!bg-[#DBD1F8] sm:w-[21.8125rem]">
		<span>{{ 'jobViews.blog.seeMore' | translate }}</span>
	</button>
</div>
