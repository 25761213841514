<div
	class="z-1000 relative"
	aria-labelledby="modal-title"
	role="dialog"
	aria-modal="true"
	[@openClose]="showModal ? 'open' : 'close'"
	data-cy="modal-container">
	<div class="fixed inset-0 bg-[#00000059] transition-opacity" aria-hidden="true"></div>
	<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
		<div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
			<div
				class="modal-content relative flex transform flex-col justify-center overflow-visible rounded-2xl border-[5px] border-[#006977D9] text-left shadow-xl transition-all xxs:!w-[18rem] xs:w-[20.875rem] sm:my-8 sm:w-[33.6875rem] md:w-[44.9375rem] lg:w-[49.9375rem] xl:w-[59.125rem]"
				[ngClass]="{
					'animate-in': showModal,
					'animate-out': !showModal,
				}"
				data-cy="modal-content">
				<div
					class="rounded-t-[10px] bg-gradient-to-br from-[#00000099] to-[#00000040] p-[0.3125rem] backdrop-blur-[10px]">
					<div class="flex flex-row-reverse">
						<img
							height="12px"
							width="12px"
							class="me-2 mt-2 cursor-pointer"
							src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fmodal%2Fx.svg?alt=media"
							[@openClose]="showModal ? 'open' : 'close'"
							(click)="toggle()"
							alt="x logo"
							tabindex="0" />
					</div>
					<h3
						class="monsterrat header-7-eb sm:header-6-eb xl:header-5-eb mb-0 mt-[2rem] px-[1.75rem] text-center text-Blanco sm:px-[4rem] md:mb-[-6rem]">
						{{ 'talentView.modalCreateProcess.title' | translate }}
					</h3>
					<img
						height="200px"
						width="200px"
						class="relative right-[2.1875rem] top-[2.5rem] z-[1100] mt-[-2.5rem] overflow-visible"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fmodal%2Fwisy detective.svg?alt=media"
						alt="wisy detective" />
				</div>
				<div class="rounded-b-[0.625rem] bg-MoradoClaro">
					<div class="flex flex-col gap-[2rem] px-[0.5rem] py-[3.125rem] sm:px-[1.5625rem] md:px-[4rem]">
						<div class="sm:text-left">
							<p class="montserrat header-5 md:header-4 text-VerdeOscuro">
								{{ 'talentView.modalCreateProcess.text' | translate }}
							</p>
						</div>
						<div class="flex flex-col gap-[2.1875rem]">
							<app-general-dropdown
								[title]="'talentView.modalCreateProcess.dropdown1.placeholder'"
								[data]="dropdown1Options"
								[error]="dropdowns[1].error"
								[id]="dropdowns[1].selection"
								(rowSelected)="setOptionValue($event, 1)">
							</app-general-dropdown>
							<app-general-dropdown
								[title]="'talentView.modalCreateProcess.dropdown2.placeholder'"
								[data]="dropdown2Options"
								[error]="dropdowns[2].error"
								[id]="dropdowns[2].selection"
								(rowSelected)="setOptionValue($event, 2)">
							</app-general-dropdown>
							<app-general-dropdown
								[title]="'talentView.modalCreateProcess.dropdown3.placeholder'"
								[data]="dropdown3Options"
								[error]="dropdowns[3].error"
								[id]="dropdowns[3].selection"
								(rowSelected)="setOptionValue($event, 3)">
							</app-general-dropdown>
						</div>
						<div class="flex items-center justify-center">
							<app-general-button
								[text]="'talentView.modalCreateProcess.button' | translate"
								[small]="true"
								[buttonType]="buttonType.MAIN_VERDE"
								[idForButton]="'modalCreateProcessSelectionId'"
								(click)="goToLink('https://talent.thewiseseeker.com/recruiter/')">
							</app-general-button>
						</div>
					</div>
				</div>
				<div
					class="z-[-1] mt-[-1rem] rounded-[10px] bg-gradient-to-br from-[#00000099] to-[#00000040] p-[0.3125rem] backdrop-blur-[10px]">
					<h3 class="poppins body-4-m sm:body-3-m xl:body-2-m mb-[1.875rem] mt-[2.875rem] text-center text-Blanco">
						{{ 'talentView.modalCreateProcess.subtitle' | translate }}
					</h3>
				</div>
			</div>
		</div>
	</div>
</div>
