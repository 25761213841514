<a [routerLink]="'/post/' + this.post.postUrl">
	<div
		class="flex h-full w-full flex-col gap-6 rounded-2xl bg-white p-4 sm:flex-row md:flex-col lg:flex-row xl:flex-col">
		<img
			[src]="this.post.postImageUrl"
			class="max-h-[15rem] min-h-[15rem] w-full max-w-full rounded-lg object-cover sm:max-h-full sm:min-h-full sm:max-w-[50%] md:max-h-[15rem] md:min-h-[15rem] md:max-w-full lg:max-h-full lg:min-h-full lg:max-w-[50%] xl:max-h-[18.75rem] xl:min-h-[18.75rem] xl:max-w-full"
			(error)="onCardImageError($event)"
			alt="post image" />
		<div
			class="flex flex-col justify-between gap-6 sm:justify-center md:justify-between lg:justify-center xl:justify-between">
			<span class="text-2xl font-bold text-VerdeOscuro">
				{{ this.post.postTitle }}
			</span>
			<span class="line-clamp-2 text-base font-normal text-VerdeOscuro">{{ this.post.postMetadescription }}</span>
			<span class="montserrat text-right text-[1rem] font-bold leading-5 text-VerdeOscuro">
				{{ this.post.postCreatedAt | date: 'dd MMM yyyy' }}</span
			>
		</div>
	</div>
</a>
