<div
	class="relative flex h-[28rem] items-center justify-center overflow-hidden bg-gradient-to-b from-AzulOscuro to-VerdeOscuro sm:h-[24.375rem]">
	<!-- Logo TWS -->
	<img
		class="w-[18rem] pt-20 sm:pt-32 md:w-[20.1875rem]"
		src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FLogo-TWS.png?alt=media"
		alt="Logo The Wise Seeker" />

	<div
		class="absolute -bottom-11 -right-[3.2rem] w-[8.5rem] sm:-bottom-16 sm:-right-[5.9rem] sm:w-[13.375rem] md:-bottom-12 md:-right-[5rem] md:w-[12rem] lg:-right-[4rem] xl:-bottom-14 xl:-right-[5.5rem] xl:w-[14rem] xxl:-bottom-36 xxl:-right-28 xxl:w-[17rem]">
		<!-- Seeky brazo -->
		<img
			class="w-full -rotate-[15deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fseeky%2Fcuerpo-seeky-sin-estrellas.svg?alt=media"
			alt="Logo The Wise Seeker" />
	</div>
	<div
		class="absolute -bottom-14 left-0 w-[6.875rem] md:w-[8.8125rem] lg:-bottom-20 lg:left-4 lg:w-[9.5625rem] xl:left-10 xxl:w-[10.5rem]">
		<!-- Wisy cuerpo entero -->
		<img
			class="w-full rotate-12 -scale-x-100 transform"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fwisy%2Fwisy-cuerpo-entero.svg?alt=media"
			alt="Logo The Wise Seeker" />
	</div>
	<div
		class="absolute -left-[0.4031rem] top-[1.8rem] w-[7rem] sm:-left-[3rem] sm:top-[0.4375rem] sm:w-[9.5625rem] md:-left-[2rem] md:-top-3 md:w-[11rem] lg:left-14 xl:left-40 xxl:left-72">
		<!-- Galleta arriba izquierda pequeña -->
		<img
			class="rotate-[6.42deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo" />
	</div>
	<div
		class="absolute left-[2rem] top-[1.7rem] w-[9.8125rem] sm:left-0 sm:top-[1rem] sm:w-[13.375rem] md:left-5 md:top-0 md:w-[16.875rem] lg:left-28 xl:left-56 xxl:left-[22rem]">
		<!-- Galleta arriba izquierda grande -->
		<img
			class="-rotate-[9.39deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo" />
	</div>
	<div
		class="absolute -bottom-6 right-20 w-[6.2rem] sm:-bottom-10 sm:right-28 sm:w-[8.5rem] md:-bottom-8 md:right-10 md:w-[8rem] lg:-bottom-11 lg:right-32 lg:w-[10.8125rem] xl:right-24 xxl:-bottom-7 xxl:right-52 xxl:w-[120px]">
		<!-- Galleta abajo derecha pequeña -->
		<img
			class="rotate-[11.36deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo" />
	</div>
</div>
