<div
	class="cookies-container fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
	<div
		class="fixed inset-x-0 bottom-0 z-50 flex flex-col items-center justify-center rounded-t-3xl bg-VerdeOscuro p-4 text-white sm:p-6">
		<div class="absolute -left-[19px] -top-[13px] w-[86px] sm:w-[114px] md:w-[72px] xxl:w-[99px]">
			<!-- Arriba izquierda pequeña -->
			<img
				class="rotate-12 md:-rotate-3"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo" />
		</div>
		<div class="absolute left-0 top-0 w-[121px] sm:w-[160px] md:-top-12 md:w-[92px] xxl:left-3 xxl:w-[130px]">
			<!-- Arriba izquierda grande -->
			<img
				class="-rotate-12 md:rotate-3"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo" />
		</div>
		<div
			class="absolute right-[10px] top-[133px] w-[65px] sm:w-[90px] md:-bottom-5 md:top-auto md:w-[72px] xxl:w-[79px]">
			<!-- Abajo derecha -->
			<img
				class="rotate-12"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo" />
		</div>
		<div class="grid grid-cols-1 items-center justify-items-center md:grid-cols-4">
			<img
				class="w-[176px]"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Ftws-cookies-log.svg?alt=media"
				alt="Logo The Wise Seeker" />
			<div class="mb-10 mt-4 text-center sm:mx-5 md:col-span-2 md:mx-10 md:text-start">
				<p class="body-5-m poppins mb-4">
					{{ 'shared.modalCookiesConsent.title' | translate }}
				</p>
				<p class="body-6-m poppins">
					{{ 'shared.modalCookiesConsent.message' | translate }}
					<br />
					{{ 'shared.modalCookiesConsent.moreInfo' | translate }}
					<a class="cursor-pointer underline" (click)="this.navigateToCookiesPage.emit()" tabindex="0">
						{{ 'shared.modalCookiesConsent.cookiesPolicy' | translate }}
					</a>
				</p>
			</div>
			<div class="buttons-container mb-4 flex flex-col gap-y-4">
				<app-general-button
					[addedClasses]="'w-full'"
					[text]="'shared.modalCookiesConsent.buttonAcceptAll' | translate | uppercase"
					[buttonType]="buttonType.BORDE_BLANCO"
					[small]="true"
					(buttonClick)="this.acceptAllCookies.emit()"></app-general-button>
				<app-general-button
					[addedClasses]="'w-full'"
					[text]="'shared.modalCookiesConsent.buttonAcceptMandatory' | translate | uppercase"
					[buttonType]="buttonType.BORDE_BLANCO"
					[small]="true"
					(buttonClick)="this.acceptMandatoryCookies.emit()"></app-general-button>
			</div>
		</div>
	</div>
</div>
