<div
	class="flex h-full flex-col items-center justify-start"
	[ngClass]="{
		'card-one': cardType === typeEnum.MORADO_CLARO,
		'card-two': cardType === typeEnum.CLARO,
		'card-three': cardType === typeEnum.OSCURO,
	}">
	@if (thereIsImage === true) {
		<div
			class="card-header montserrat flex items-center justify-center text-center"
			[ngClass]="{
				'lg:header-3 md:header-4 header-3': cardType === typeEnum.CLARO || cardType === typeEnum.OSCURO,
				'text-[#F2F2F2]': cardType === typeEnum.OSCURO || cardType === typeEnum.MORADO_CLARO,
				'text-VerdeOscuro': cardType === typeEnum.CLARO,
				'bg-AzulOscuro': cardType === typeEnum.MORADO_CLARO,
				'bg-MoradoClaro': cardType === typeEnum.CLARO,
				'bg-VerdeOscuro': cardType === typeEnum.OSCURO,
				'bg-VerdeHover': greenCircle === true,
			}">
			<img class="img-header" src="{{ headerUrl }}" alt="header-image" />
		</div>
	} @else {
		<div
			class="card-header montserrat flex items-center justify-center text-center"
			[ngClass]="{
				'lg:header-3 md:header-4 header-3': cardType === typeEnum.CLARO || cardType === typeEnum.OSCURO,
				'text-[#F2F2F2]': cardType === typeEnum.OSCURO || cardType === typeEnum.MORADO_CLARO,
				'text-VerdeOscuro': cardType === typeEnum.CLARO,
				'bg-AzulOscuro': cardType === typeEnum.MORADO_CLARO,
				'bg-MoradoClaro': cardType === typeEnum.CLARO,
				'bg-VerdeOscuro': cardType === typeEnum.OSCURO,
				'bg-VerdeHover': greenCircle === true,
			}">
			{{ header }}
		</div>
	}
	<div
		class="card-title montserrat my-5 flex items-center justify-center text-center"
		[ngClass]="{
			'text-VerdeNeon': cardType === typeEnum.MORADO_CLARO || cardType === typeEnum.OSCURO,
			'text-VerdeOscuro': cardType === typeEnum.CLARO,
			'xs:h-4-eb-20 sm:h-4-eb-20 lg:h-4-eb-25':
				cardType === typeEnum.MORADO_CLARO || cardType === typeEnum.OSCURO || cardType === typeEnum.CLARO,
		}">
		{{ title }}
	</div>
	<div
		class="card-text poppins flex items-center justify-center text-center"
		[ngClass]="{
			'text-[#F2F2F2]': cardType === typeEnum.MORADO_CLARO || cardType === typeEnum.OSCURO,
			'text-VerdeOscuro': cardType === typeEnum.CLARO,
			'xs:p-m-16 md:p-m-18 lg:p-m-16 xl:p-m-18':
				cardType === typeEnum.MORADO_CLARO || cardType === typeEnum.OSCURO || cardType === typeEnum.CLARO,
		}">
		{{ text }}
	</div>
</div>
