<div [ngClass]="this.isBlog ? 'shadow-xl' : ''" class="upper-section flex items-center overflow-hidden shadow-xl">
	<div
		class="flex items-center overflow-hidden"
		[ngClass]="{
			'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.VERDE_MORADO,
			'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO,
			'bg-VerdeNeon': carrouselType === typeEnum.NEON_VERDE,
		}">
		<h2
			class="h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 flex animate-loop-scroll-left-slow items-center text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType === typeEnum.NEON_VERDE,
			}">
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>

		<h2
			class="h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 flex animate-loop-scroll-left-slow items-center text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType === typeEnum.NEON_VERDE,
			}"
			aria-hidden="true">
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>
		<h2
			class="h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 flex animate-loop-scroll-left-slow items-center text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType === typeEnum.NEON_VERDE,
			}"
			aria-hidden="true">
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>
	</div>
</div>

@if (!isBlog) {
	<div
		class="flex items-center overflow-hidden"
		[ngClass]="{
			'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.NEON_VERDE,
			'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO || carrouselType === typeEnum.VERDE_MORADO,
			'enterprise-section': isEnterprise,
			'text-section-jobs': !isEnterprise,
		}">
		@if (isEnterprise) {
			<div class="logo-slider relative flex animate-loop-scroll-right">
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" alt="logo" />
				}
			</div>
			<div class="logo-slider relative flex animate-loop-scroll-right" aria-hidden="true">
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" alt="logo" />
				}
			</div>
			<div class="logo-slider relative flex animate-loop-scroll-right" aria-hidden="true">
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" alt="logo" />
				}
			</div>
		} @else {
			<h2
				class="text-slider h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 lower-section text-section-jobs relative flex animate-loop-scroll-right-slow items-center text-VerdeOscuro"
				[ngClass]="{
					'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.NEON_VERDE,
					'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO || carrouselType === typeEnum.VERDE_MORADO,
				}">
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
			<h2
				class="text-slider h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 lower-section text-section-jobs relative flex animate-loop-scroll-right-slow items-center text-VerdeOscuro"
				[ngClass]="{
					'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.NEON_VERDE,
					'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO || carrouselType === typeEnum.VERDE_MORADO,
				}"
				aria-hidden="true">
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
			<h2
				class="text-slider h2-slider montserrat xs:banner-b-30 sm:banner-b-30 xl:banner-b-40 lower-section text-section-jobs relative flex animate-loop-scroll-right-slow items-center text-VerdeOscuro"
				[ngClass]="{
					'bg-Verde': carrouselType === typeEnum.VERDE_VERDE || carrouselType === typeEnum.NEON_VERDE,
					'bg-MoradoClaro': carrouselType === typeEnum.MORADO_MORADO || carrouselType === typeEnum.VERDE_MORADO,
				}"
				aria-hidden="true">
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
		}
	</div>
}
