import { Component, Inject, Input, PLATFORM_ID, TransferState } from '@angular/core';
import { ButtonType } from '../general-button/general-button.component';
import { isPlatformServer } from '@angular/common';
import { BlogPost } from '../../../interfaces/blog';
import { POSTS_VALUE } from '../../../../tokens';
import { BlogUrl } from '../../blog/blog.url';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
	selector: 'app-blog',
	templateUrl: './blog-job-section.component.html',
	styleUrl: './blog-job-section.component.sass',
})
export class BlogJobSectionComponent {
	buttonType = ButtonType;
	@Input() posts: BlogPost[] = [
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
		{
			postId: '1',
			category: {
				categoryId: '1',
			},
			postTitle: 'Test ' + 1,
			postMetadescription: 'Test',
			postUrl: 'urltest',
			postImageUrl: 'https://placehold.co/600x400',
		},
	];
	@Input() numPosts: number = 0;

	readonly blogUrl = BlogUrl;

	constructor(
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId: object,
		private http: HttpClient,
		private router: Router
	) {
		if (isPlatformServer(this.platformId)) {
			this.http.get<BlogPost[]>('/api/posts').subscribe((response) => {
				transferState.set(POSTS_VALUE, response);
				this.serializePosts(response);
			});
		} else {
			const getPosts = transferState.get(POSTS_VALUE, []);
			if (getPosts.length > 0) {
				this.serializePosts(getPosts);
			} else {
				this.http.get<BlogPost[]>('/api/posts').subscribe((response) => {
					this.serializePosts(response);
				});
			}
		}
	}

	serializePosts(posts: any) {
		const defaultPosts = this.posts.slice();

		this.posts = posts.map((post: any) => {
			return {
				postId: post.post_id,
				category: post.category_id,
				postTitle: post.post_title,
				postMetadescription: post.post_metadescription,
				postUrl: post.post_url,
				postImageUrl: post.post_image_url,
			};
		});

		if (this.posts.length < 6) {
			const remainingPosts = 6 - this.posts.length;
			this.posts = this.posts.concat(defaultPosts.slice(0, remainingPosts));
		}
	}

	goToLink(url: string) {
		this.router.navigate([url]);
	}
}
