import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-ktis',
	templateUrl: './ktis.component.html',
	styleUrl: './ktis.component.sass',
})
export class KtisComponent {
	@Input() num_users: number = 0;
	@Input() num_jobs: number = 0;
	@Input() num_evals: number = 0;

	readonly animationDuration = 2500; // 2.5sec
}
