<!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
<div
	class="montserrat h-full w-full hover:bg-[#FFFFFF66]"
	(click)="toggleDropdown()"
	[ngClass]="{
		'bg-[#FFFFFF66] shadow-[rgba(0,71,81,1)_0px_0px_10px_1px]': isDropdownOpen,
		'error-border': error,
		'green-border': !error,
		'locations-button': isLocationFilter,
		'categories-button': !isLocationFilter,
	}"
	data-cy="dropdown-button">
	<label for="dropdown-input">
		<div class="flex w-full flex-row">
			<!-- eslint-disable-next-line @angular-eslint/template/alt-text -->
			<img src="{{ imageUrl }}" class="ps-[1.625rem]" [ngClass]="hasImage ? '' : 'hidden'" />
			<div
				class="dropdown-div flex h-[3rem] w-full items-center justify-between px-2 text-VerdeOscuro"
				[ngClass]="hasAutocomplete ? '-z-10' : ''">
				<input
					*ngIf="hasAutocomplete"
					class="w-12/12 xs:button-4 sm:button-4 md:button-3 xl:button-2 bg-transparent text-start outline-none placeholder:text-VerdeOscuro"
					id="dropdown-input"
					type="text"
					[ngClass]="{
						'xs:button-3 sm:button-3': isLocationFilter,
						'xs:button-4 sm:button-4 md:button-3': !isLocationFilter,
						'!font-extrabold': selectedRow?.title && !isLocationFilter,
						'!font-bold': !selectedRow?.title && !isLocationFilter,
					}"
					(keyup)="updateList($event)"
					(click)="toggleDropdown()"
					[placeholder]="selectedRow?.title || title | translate" />
				<span
					*ngIf="!hasAutocomplete"
					class="w-12/12 xs:button-4 sm:button-4 md:button-3 xl:button-2 dropdown-text text-start"
					[ngClass]="{
						'xs:button-3 sm:button-3': isLocationFilter,
						'xs:button-4 sm:button-4 md:button-3': !isLocationFilter,
						'!font-extrabold': selectedRow?.title && !isLocationFilter,
						'!font-bold': !selectedRow?.title && !isLocationFilter,
					}"
					>{{ selectedRow?.title || title | translate }}</span
				>
				<!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
				<img
					[ngClass]="hasChevron ? '' : 'hidden'"
					[src]="
						isDropdownOpen
							? 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-up-fat.svg?alt=media'
							: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-down-fat.svg?alt=media'
					"
					(click)="toggleDropdown($event)"
					alt="chevron image" />
			</div>
		</div>
	</label>
	<div class="relative">
		@if (isDropdownOpen) {
			<div
				class="dropdown absolute flex w-full flex-col border-VerdeOscuro bg-[#FFFFFF80] shadow-[rgba(0,71,81,1)_0px_0px_10px_1px] backdrop-blur-[15px]"
				[ngClass]="{
					'z-50': isLocationFilter,
					'h-[20rem]': isLocationFilter,
					'overflow-scroll': isLocationFilter,
				}"
				data-cy="dropdown-div">
				@for (d of data; track d) {
					<button (click)="selectOption(d)">
						<div class="flex w-full items-center justify-start bg-transparent text-left font-bold text-VerdeOscuro">
							<span class="button-3 dropdown-option ml-[0.3125rem] w-full">{{ d.title | translate }}</span>
						</div>
					</button>
				}
			</div>
		}
	</div>
</div>
