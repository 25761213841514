import { InjectionToken, makeStateKey } from '@angular/core';
import { KPI } from './app/interfaces/kpi';
import { Process } from './app/interfaces/processess';
import { BlogCategory, BlogPost } from './app/interfaces/blog';
import { Request } from 'express';
import { CampaignInput } from './app/modules/campaign/views/campaign/campaign.component';

export const KPIS_VALUE = makeStateKey<KPI>('kpisKey');
export const PROCESSES_VALUE = makeStateKey<Process[]>('processKey');
export const POSTS_VALUE = makeStateKey<BlogPost[]>('postsKey');
export const POSTCATEGORIES_VALUE = makeStateKey<BlogCategory[]>('categoriesKey');
export const POST_BY_URL_VALUE = makeStateKey<BlogPost>('postByUrlKey');
export const PROCESSES_BY_FILTERS_VALUE = makeStateKey<Process[]>('processesByFilterKey');
export const CAMPAIGN_VALUE = makeStateKey<CampaignInput>('campaignKey');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
export const REQUEST = new InjectionToken<Request>('REQUEST');
