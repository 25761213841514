<nav class="absolute left-0 right-0 z-50">
	<section class="main-container absolute flex justify-between">
		<section class="menu-bar flex justify-between">
			<div>
				<a class="" [routerLink]="homeUrl.Base">
					<img class="" [src]="logo" alt="Logo" />
				</a>
			</div>
			<div class="menu-buttons">
				<a
					[routerLink]="homeUrl.Base"
					routerLinkActive="router-link-active"
					[routerLinkActiveOptions]="{ exact: true }"
					class="button-3 montserrat menu-button"
					[ngClass]="{
						'router-link-active': isHomeLinkActive(),
						'menu-light-buttons': theme === navbarTheme.LIGHT,
						'menu-dark-buttons': theme !== navbarTheme.LIGHT,
					}"
					>HOME</a
				>
				<a
					[routerLink]="jobUrl.Base"
					routerLinkActive="router-link-active"
					class="button-3 montserrat menu-button"
					[ngClass]="theme === navbarTheme.LIGHT ? 'menu-light-buttons' : 'menu-dark-buttons'"
					>{{ 'shared.navbar.buscoEmpleo' | translate | uppercase }}</a
				>
				<a
					[routerLink]="talentUrl.Base"
					routerLinkActive="router-link-active"
					class="button-3 montserrat menu-button"
					[ngClass]="theme === navbarTheme.LIGHT ? 'menu-light-buttons' : 'menu-dark-buttons'"
					>{{ 'shared.navbar.buscoTalento' | translate | uppercase }}</a
				>
			</div>
		</section>

		<section class="access-bar access-bar-collapsed transform transition-transform duration-300 ease-in-out">
			<section class="access-buttons flex h-16 items-center justify-around">
				<app-general-button
					[small]="true"
					[addedClasses]="'!button-3'"
					[text]="'shared.navbar.acceder' | translate | uppercase"
					[buttonType]="buttonTheme"
					(click)="goToLink(redirectURl)"></app-general-button>
				<!-- <button class="button-3 montserrat language-button" [ngStyle]="{borderColor: theme==navbarTheme.LIGHT ? '#004751' : '#f2f2f2'}">IDIOMA</button> -->
				<button
					class="button-3 montserrat language-button"
					(click)="toggleDropdown()"
					[ngClass]="theme === navbarTheme.LIGHT ? 'light-btn' : 'dark-btn'">
					<div class="flex items-center justify-center">
						<div class="ml-2">
							<img src="{{ selectedLanguage.icon }}" alt="icon image" />
						</div>
						<div class="ml-2">
							<span class="button-3">{{ selectedLanguage.short }}</span>
						</div>
					</div>
					<!--LANGS-->
					<div class="relative" *ngIf="isDropdownOpen">
						<div
							(clickOutside)="closeDropdown()"
							class="dropdown absolute flex flex-col justify-center"
							[ngClass]="theme === navbarTheme.LIGHT ? 'light' : 'dark'">
							<button (click)="changeLanguage(language)" *ngFor="let language of platformLanguages">
								<div class="flex items-center">
									<div class="ml-2">
										<img src="{{ language.icon }}" alt="icon image" />
									</div>
									<div class="ml-2">
										<span class="button-3">{{ language.short }}</span>
									</div>
								</div>
							</button>
						</div>
					</div>
				</button>
			</section>
			<section
				id="sidebarSection"
				class="sidebar-section absolute inset-y-0 right-0 flex transform items-center transition-transform duration-300 ease-in-out">
				<!-- Mobile menu button-->
				<button id="sidebarButton" (click)="toggleSidebar()" type="button" class="mobile-menu-button">
					<img
						*ngIf="!opened"
						class="block h-11 w-11"
						[src]="theme === navbarTheme.LIGHT ? openMenuDark : openMenuLight"
						alt="menu button" />
					<img
						*ngIf="opened"
						class="block h-11 w-11"
						[src]="theme === navbarTheme.LIGHT ? closeMenuDark : closeMenuLight"
						alt="menu button" />
				</button>

				<div class="sidebar-menu absolute overflow-y-auto" id="sidebar">
					<!-- Sidebar Content -->
					<div class="sidebar-content flex flex-col justify-between p-4 pr-[0.275rem] backdrop-blur-[0.3125rem]">
						<ul class="sidebar-list">
							<li>
								<button
									(click)="goToLink(homeUrl.Base)"
									routerLinkActive="router-link-active"
									[routerLinkActiveOptions]="{ exact: true }"
									class="button-3 montserrat menu-side-button"
									[ngClass]="theme === navbarTheme.LIGHT ? 'menu-light-buttons' : 'menu-dark-buttons'">
									HOME
								</button>
							</li>
							<li>
								<button
									[routerLink]="jobUrl.Base"
									routerLinkActive="router-link-active"
									class="button-3 montserrat menu-side-button"
									[ngClass]="theme === navbarTheme.LIGHT ? 'menu-light-buttons' : 'menu-dark-buttons'">
									{{ 'shared.navbar.buscoEmpleo' | translate | uppercase }}
								</button>
							</li>
							<li>
								<button
									[routerLink]="talentUrl.Base"
									routerLinkActive="router-link-active"
									class="button-3 montserrat menu-side-button"
									[ngClass]="theme === navbarTheme.LIGHT ? 'menu-light-buttons' : 'menu-dark-buttons'">
									{{ 'shared.navbar.buscoTalento' | translate | uppercase }}
								</button>
							</li>
						</ul>
						<ul class="sidebar-list gap-4">
							<li>
								<button
									class="button-3 montserrat access-button"
									(click)="goToLink(redirectURl)"
									[ngStyle]="{
										background: theme === navbarTheme.LIGHT ? '#004751' : '#f2f2f2',
										color: theme === navbarTheme.LIGHT ? '#f2f2f2' : '#004751',
									}">
									{{ 'shared.navbar.acceder' | translate | uppercase }}
								</button>
							</li>
							<!-- <li><button class="button-3 montserrat language-button" [ngStyle]="{borderColor: theme==navbarTheme.LIGHT ? '#004751' : '#f2f2f2'}">IDIOMA</button></li> -->
							<li>
								<button
									class="button-3 montserrat language-button"
									(click)="toggleDropdown()"
									[ngStyle]="{
										borderColor: theme === navbarTheme.LIGHT ? '#004751' : '#f2f2f2',
									}">
									<div class="flex items-center justify-center">
										<div class="ml-2">
											<img src="{{ selectedLanguage.icon }}" alt="language icon" />
										</div>
										<div class="ml-2">
											<span class="button-3">{{ selectedLanguage.short }}</span>
										</div>
									</div>
									<!--LANGS-->
									<div class="relative" *ngIf="isDropdownOpen">
										<div
											(clickOutside)="closeDropdown()"
											[ngClass]="theme === navbarTheme.LIGHT ? 'light' : 'dark'"
											class="dropdown bg-lang absolute flex flex-col justify-center">
											<button (click)="changeLanguage(language)" *ngFor="let language of platformLanguages">
												<div class="flex items-center">
													<div class="ml-2">
														<img src="{{ language.icon }}" alt="language icon" />
													</div>
													<div class="ml-2">
														<span class="button-3">{{ language.short }}</span>
													</div>
												</div>
											</button>
										</div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</section>
	</section>
</nav>
