import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	OnChanges,
} from '@angular/core';

export interface DataDropdown {
	id: number;
	title: string;
}

@Component({
	selector: 'app-general-dropdown',
	templateUrl: './general-dropdown.component.html',
	styleUrl: './general-dropdown.component.sass',
})
export class GeneralDropdownComponent implements OnInit, OnChanges {
	@Input() data: DataDropdown[] = [
		{ id: 1, title: 'Dropdown 1' },
		{ id: 2, title: 'Dropdown 2' },
		{ id: 3, title: 'Dropdown 3' },
	];
	@Input() initialData: DataDropdown[] = [
		{ id: 1, title: 'Dropdown 1' },
		{ id: 2, title: 'Dropdown 2' },
		{ id: 3, title: 'Dropdown 3' },
	];
	@Input() title: string = '';
	@Input() error: boolean = false;
	@Input() hasImage: boolean = false;
	@Input() imageUrl: string = '';
	@Input() hasChevron: boolean = true;
	@Input() isLocationFilter: boolean = false;
	@Input() hasAutocomplete: boolean = false;

	@Output() rowSelected = new EventEmitter<string | null>();

	selectedRow?: DataDropdown;
	isDropdownOpen: boolean = false;

	constructor(private eRef: ElementRef) {}

	ngOnInit() {
		if (this.hasAutocomplete) {
			this.data = [...this.initialData];
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['initialData']) {
			this.data = [...this.initialData];
		}
	}

	toggleDropdown(event?: MouseEvent) {
		if (event) {
			event.stopPropagation();
		}
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	selectOption(row: DataDropdown) {
		this.selectedRow = row;
		this.isDropdownOpen = false;
		this.rowSelected.emit(this.selectedRow.title);
	}

	updateList(event: Event) {
		event.stopPropagation();
		this.data = [
			...this.initialData.filter((e) =>
				e.title.toLowerCase().includes((event.target as HTMLInputElement).value.toLowerCase())
			),
		];
	}

	focusOnInput() {
		if (this.hasAutocomplete) {
			document.getElementById('dropdown-input')?.focus();
		}
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.closeDropdown();
		}
	}
}
