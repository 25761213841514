<button
	(click)="clickEvent()"
	class="montserrat button-2 rounded-3xl px-10 py-[0.6875rem]"
	[class.button-2]="small === false"
	[class.button-3]="small === true && (typeEnum.MAIN_VERDE === buttonType || typeEnum.MAIN_MORADO === buttonType)"
	[class.header-5]="small === true && (typeEnum.MAIN_BLANCO === buttonType || typeEnum.BORDE_BLANCO === buttonType)"
	[ngClass]="style"
	[id]="idForButton">
	{{ text }}
</button>
