import { Component, Input, OnInit } from '@angular/core';
import { Theme } from './theme';
import { Router } from '@angular/router';

@Component({
	selector: 'app-cifras-empleo',
	templateUrl: './cifras-empleo.component.html',
	styleUrl: './cifras-empleo.component.sass',
})
export class CifrasEmpleoComponent implements OnInit {
	@Input() num_users: number = 0;
	@Input() num_jobs: number = 0;
	@Input() num_evals: number = 0;

	@Input() theme: Theme = 1;
	CardsTheme = Theme;

	readonly animationDuration = 2500; // 2.5sec

	usuariosDark: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FUsuarios.svg?alt=media&token=06681175-acce-4fab-95ce-37c5a5f7506a';
	usuariosLight: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FUsuariosLight.svg?alt=media&token=396808cb-7369-41d1-aa3c-93af50cdd7bf';
	trabajosDark: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FTrabajos.svg?alt=media&token=88d549b5-060a-4f7f-b766-fb9a700d8af5';
	trabajosLight: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FTrabajosLight.svg?alt=media&token=b68112f7-e456-479a-99f6-f887dede5659';
	evaluacionesDark: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FEvaluaciones.svg?alt=media&token=390fbe69-000f-4d20-8b69-cd0a43780e5c';
	evaluacionesLight: string =
		'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FEvaluacionesLight.svg?alt=media&token=98325330-bea2-4c9b-80df-90519039aaaf';

	constructor(public router: Router) {}

	ngOnInit() {
		this.getCurrentTheme();
	}

	getCurrentTheme(): void {
		this.theme = this.router.url == '/buscar-talento' ? Theme.DARK : Theme.LIGHT;
	}
}
