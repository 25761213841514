export interface PlatformLanguage {
	id: number;
	name: string;
	short: string;
	icon: string;
}

export const AllLanguages: PlatformLanguage[] = [
	{
		id: 0,
		name: 'Español',
		short: 'ES',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FSpanish.svg?alt=media',
	},
	{
		id: 1,
		name: 'English',
		short: 'EN',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FEnglish.svg?alt=media',
	},
	{
		id: 2,
		name: 'Português',
		short: 'PT',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FPortuguese.svg?alt=media',
	},
	{
		id: 3,
		name: 'Brasileiro',
		short: 'BR',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FBrazilian.svg?alt=media',
	},
	{
		id: 4,
		name: 'Italiano',
		short: 'IT',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FItalian.svg?alt=media',
	},
	{
		id: 5,
		name: 'Français',
		short: 'FR',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FFrench.svg?alt=media',
	},
	{
		id: 6,
		name: 'Deutsche',
		short: 'DE',
		icon: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flanguages_%2FGermany.svg?alt=media',
	},
];
