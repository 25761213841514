import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

export enum ButtonType {
	MAIN_MORADO = 1,
	BORDE_VERDE = 2,
	BORDE_BLANCO = 3,
	MAIN_VERDE = 4,
	MAIN_BLANCO = 5,
	MAIN_VERDE_CLARO = 6,
}

@Component({
	selector: 'app-general-button',
	templateUrl: './general-button.component.html',
	styleUrl: './general-button.component.sass',
})
export class GeneralButtonComponent implements OnInit, OnChanges {
	@Input() text: string = 'Click me!';
	@Input() buttonType: ButtonType = ButtonType.MAIN_MORADO;
	@Input() small: boolean = false;
	@Input() addedClasses: string = '';
	@Input() idForButton: string = '';
	@Output() buttonClick: EventEmitter<any> = new EventEmitter();

	typeEnum = ButtonType;
	style: string = '';

	ngOnInit() {
		this.chooseStyle();
	}

	ngOnChanges() {
		this.chooseStyle();
	}

	clickEvent() {
		this.buttonClick.emit('');
	}

	chooseStyle() {
		switch (this.buttonType) {
			case ButtonType.MAIN_MORADO: {
				this.style = 'bg-MoradoClaro text-AzulOscuro hover:!bg-[#DBD1F8] focus:!bg-[#9276E5] ' + this.addedClasses;
				break;
			}
			case ButtonType.BORDE_VERDE: {
				this.style =
					'bg-transparent text-VerdeOscuro !border-[3px] border-VerdeOscuro hover:!bg-[#DBD1F8] focus:!bg-[#A38AED] hover:shadow-[0rem_0rem_0.625rem_0rem]' +
					this.addedClasses;
				break;
			}
			case ButtonType.BORDE_BLANCO: {
				this.style =
					'bg-transparent text-Blanco !border-[3px] border-Blanco hover:!bg-[#003A42] focus:!bg-[#005E6B] hover:shadow-[0rem_0rem_0.625rem_0rem]' +
					this.addedClasses;
				break;
			}
			case ButtonType.MAIN_VERDE: {
				this.style = 'bg-VerdeOscuro text-Blanco hover:!bg-[#005E6B] focus:!bg-[#003A42] ' + this.addedClasses;
				break;
			}
			case ButtonType.MAIN_BLANCO: {
				this.style = 'bg-Blanco text-VerdeOscuro hover:!bg-[#FFFFFF] focus:!bg-[#DEDEDE] ' + this.addedClasses;
				break;
			}
			case ButtonType.MAIN_VERDE_CLARO: {
				this.style = 'bg-VerdeHover text-VerdeOscuro' + this.addedClasses;
				break;
			}
		}
	}
}
