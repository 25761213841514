import { Component, Input } from '@angular/core';
import { ButtonType } from '../general-button/general-button.component';
import { CookieService } from '../../../services/cookie-service.service';
import { LegalUrl } from '../../legal/legal.url';
import { CookiesUrl } from '../../cookies/cookies.url';
import { BlogUrl } from '../../blog/blog.url';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.sass',
})
export class FooterComponent {
	readonly legalUrl = LegalUrl;
	readonly cookiesUrl = CookiesUrl;
	readonly blogUrl = BlogUrl;

	@Input() backgroundColorClass: string = 'bg-BlancoNieve';
	buttonType = ButtonType;

	constructor(private cookiesService: CookieService) {}

	goToLink(url: string) {
		window.open(url, '_blank');
	}

	mail() {
		const mail = document.createElement('a');
		mail.href = 'mailto:info@thewiseseeker.com?subject=&body=';
		mail.click();
	}

	configCookies() {
		this.cookiesService.deleteAllCookies();
	}
}
