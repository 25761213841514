<div
	class="ktis-container flex w-full bg-Verde text-VerdeOscuro xs:flex-col sm:flex-col md:columns-3 md:flex-row md:py-5 lg:py-7 xl:py-9 xxl:py-11">
	<div
		class="users relative flex flex-col items-center justify-center xs:pb-24 xs:pt-12 sm:pb-24 sm:pt-8 md:w-1/3 md:p-0">
		<div class="logo-container user-logo flex items-center justify-center">
			<img
				class="user-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fusers.svg?alt=media"
				alt="user icon" />
		</div>
		<div class="flex flex-col items-center justify-center">
			<!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
			<h1
				[countUp]="num_users"
				[duration]="animationDuration"
				class="montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"></h1>
			<p class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25">
				{{ 'shared.ktisMessage.usersMessage' | translate }}
			</p>
		</div>
	</div>
	<hr class="bg-VerdeOscuro md:hidden" />
	<div class="vertical-line bg-VerdeOscuro xs:hidden sm:hidden md:block"></div>
	<div
		class="jobs relative flex flex-col items-center justify-center xs:pb-24 xs:pt-8 sm:pb-24 sm:pt-8 md:w-1/3 md:p-0">
		<div class="logo-container jobs-logo flex items-center justify-center">
			<img
				class="jobs-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fjobs.svg?alt=media"
				alt="job icon" />
		</div>
		<div class="flex flex-col items-center justify-center">
			<!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
			<h1
				[countUp]="num_jobs"
				[duration]="animationDuration"
				class="montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"></h1>
			<p class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25">
				{{ 'shared.ktisMessage.jobsMessage' | translate }}
			</p>
		</div>
	</div>
	<hr class="bg-VerdeOscuro md:hidden" />
	<div class="vertical-line bg-VerdeOscuro xs:hidden sm:hidden md:block"></div>
	<div
		class="evals relative flex flex-col items-center justify-center xs:pb-10 xs:pt-8 sm:pb-12 sm:pt-8 md:w-1/3 md:p-0">
		<div class="logo-container evals-logo flex items-center justify-center">
			<img
				class="evals-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fevals.svg?alt=media"
				alt="evals icon" />
		</div>
		<div class="flex flex-col items-center justify-center">
			<!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
			<h1
				[countUp]="num_evals"
				[duration]="animationDuration"
				class="montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"></h1>
			<p class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25">
				{{ 'shared.ktisMessage.evalsMessage' | translate }}
			</p>
		</div>
	</div>
</div>
