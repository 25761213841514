import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '../general-button/general-button.component';
import { DataDropdown } from '../general-dropdown/general-dropdown.component';
import { trigger, state, style, animate, transition } from '@angular/animations';

interface DropdownState {
	selection: string | null;
	error: boolean;
}

@Component({
	selector: 'app-create-process-modal',
	templateUrl: './create-process-modal.component.html',
	styleUrl: './create-process-modal.component.sass',
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					visibility: 'visible',
					opacity: 100,
					display: 'block',
				})
			),
			state(
				'close',
				style({
					visibility: 'invisible',
					opacity: 0,
					display: 'none',
				})
			),
			transition('open => close', [animate('0.25s ease-in')]),
			transition('close => open', [animate('0.25s ease-out')]),
		]),
	],
})
export class CreateProcessModalComponent {
	@Input() showModal: boolean = false;

	@Output() toggleModal: EventEmitter<boolean> = new EventEmitter();

	buttonType = ButtonType;

	dropdown1Options: DataDropdown[] = [
		{ id: 1, title: 'talentView.modalCreateProcess.dropdown1.option1' },
		{ id: 2, title: 'talentView.modalCreateProcess.dropdown1.option2' },
		{ id: 3, title: 'talentView.modalCreateProcess.dropdown1.option3' },
		{ id: 4, title: 'talentView.modalCreateProcess.dropdown1.option4' },
		{ id: 5, title: 'talentView.modalCreateProcess.dropdown1.option5' },
	];

	dropdown2Options: DataDropdown[] = [
		{ id: 1, title: 'talentView.modalCreateProcess.dropdown2.option1' },
		{ id: 2, title: 'talentView.modalCreateProcess.dropdown2.option2' },
		{ id: 3, title: 'talentView.modalCreateProcess.dropdown2.option3' },
	];

	dropdown3Options: DataDropdown[] = [
		{ id: 1, title: 'talentView.modalCreateProcess.dropdown3.option1' },
		{ id: 2, title: 'talentView.modalCreateProcess.dropdown3.option2' },
		{ id: 3, title: 'talentView.modalCreateProcess.dropdown3.option3' },
		{ id: 4, title: 'talentView.modalCreateProcess.dropdown3.option4' },
	];

	dropdowns: Record<number, DropdownState> = {
		1: { selection: '', error: false },
		2: { selection: '', error: false },
		3: { selection: '', error: false },
	};

	toggle() {
		this.toggleModal.emit();
	}

	setOptionValue(event: string | null, idDropdown: number) {
		if (this.dropdowns[idDropdown]) {
			this.dropdowns[idDropdown].selection = event;
			this.dropdowns[idDropdown].error = false;
		}
	}

	goToLink(url: string): void {
		let allSelected = true;

		for (const id in this.dropdowns) {
			const dropdown = this.dropdowns[id];
			if (dropdown.selection === '') {
				dropdown.error = true;
				allSelected = false;
			} else {
				dropdown.error = false;
			}
		}

		if (allSelected) {
			window.open(url, '_blank');
		}
	}
}
