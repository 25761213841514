<!-- Footer container -->
<footer class="w-full" [class]="backgroundColorClass">
	<div class="main-container flex flex-col bg-VerdeOscuro py-10 text-[#F2F2F2] xxl:pt-[4.125rem]">
		<img
			class="logo mb-5 h-[7.25rem] w-[13.125rem] object-cover sm:h-[6.9375rem]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FLogo-TWS.png?alt=media"
			alt="tws logo" />
		<div class="grid-wrapper flex flex-col">
			<div class="info flex flex-col">
				<div class="flex flex-row">
					<a
						[routerLink]="'/' + blogUrl.Base"
						class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 flex items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fedit.svg?alt=media"
							alt="Blog"
							class="pr-3" />
						Blog The Wise Seeker</a
					>
				</div>

				<div class="flex flex-row">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fenvelope-icon.svg?alt=media"
						alt="Email"
						class="pr-3" />
					<a (click)="mail()" class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20" tabindex="0"
						>info&#64;thewiseseeker.com</a
					>
				</div>
				<div class="flex flex-row">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Ftelephone-icon.svg?alt=media"
						alt="Phone number"
						class="pr-3" />
					<a href="tel:+34919152400" class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20"
						>+34 91 915 24 00</a
					>
				</div>
			</div>
			<div class="rrss">
				<a (click)="goToLink('https://www.instagram.com/thewiseseeker_oficial/')" tabindex="0">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Finstagram.svg?alt=media"
						alt="Instagram"
						class="w-[2.5rem] sm:w-[3.375rem]" />
				</a>
				<a (click)="goToLink('https://www.linkedin.com/company/the-wise-seeker/mycompany/')" tabindex="0">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Flinkedin.svg?alt=media"
						alt="Linkedin"
						class="w-[2.5rem] sm:w-[3.375rem]" />
				</a>
				<a (click)="goToLink('https://www.tiktok.com/@thewiseseeker_')" tabindex="0">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Ftiktok.svg?alt=media"
						alt="TikTok"
						class="w-[2.5rem] sm:w-[3.375rem]" />
				</a>
				<a (click)="goToLink('https://www.youtube.com/@thewiseseeker_')" tabindex="0">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fyoutube.svg?alt=media"
						alt="Youtube"
						class="w-[2.5rem] sm:w-[3.375rem]" />
				</a>
				<a (click)="goToLink('https://twitter.com/thewiseseeker_')" tabindex="0">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fx.svg?alt=media"
						alt="X"
						class="w-[2.5rem] sm:w-[3.375rem]" />
				</a>
			</div>
			<div class="descubre flex flex-col gap-5">
				<h2 class="montserrat header-8-eb sm:header-5-eb">
					{{ 'shared.footer.discoverMessage' | translate }}
				</h2>
				<div class="marketplace-buttons flex shrink-0 flex-row">
					<app-general-button
						[text]="'Google Play'"
						[buttonType]="buttonType.BORDE_BLANCO"
						[small]="true"
						(buttonClick)="
							goToLink('https://play.google.com/store/apps/details?id=com.thewiseseeker.mobile')
						"></app-general-button>
					<app-general-button
						[text]="'Apple Store'"
						[buttonType]="buttonType.BORDE_BLANCO"
						[small]="true"
						(buttonClick)="goToLink('https://apps.apple.com/es/app/the-wise-seeker/id1661036956')"></app-general-button>
				</div>
			</div>
			<div class="legal flex flex-col gap-5">
				<div>
					<p class="legal-title montserrat xs:h-5-20 sm:h-5-20">
						{{ 'shared.footer.legal.titleMessage' | translate }}
					</p>
				</div>
				<div
					class="legal-links montserrat xs:body-6-m sm:body-4-m flex flex-row items-start justify-between self-stretch">
					<a [routerLink]="legalUrl.Base">{{ 'shared.footer.legal.warningMessage' | translate }}</a>
					<a [routerLink]="cookiesUrl.Base">{{ 'shared.footer.legal.cookiesPolicyMessage' | translate }}</a>
					<button (click)="configCookies()">
						{{ 'shared.footer.legal.cookiesConfigMessage' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="flex w-full flex-col items-center justify-between bg-white px-5 py-5">
		<div
			class="flex flex-row justify-center xs:w-[22.5rem] xs:gap-[1.625rem] sm:w-[36rem] sm:gap-[1.375rem] md:w-[48rem] md:gap-[4.375rem] lg:w-[62rem] lg:gap-[1.698rem] xl:w-[75rem] xl:gap-[2.781rem] xxl:w-[87.5rem] xxl:gap-[5.149rem]">
			<div
				class="flex flex-col xs:gap-[0.688rem] sm:gap-[1.063rem] md:gap-[1.063rem] lg:flex-row lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem]">
				<div
					class="flex flex-row justify-end xs:gap-[1.861rem] sm:gap-[1.671rem] md:gap-[4.809rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem]">
					<img
						class="object-contain xs:h-[1.375rem] xs:w-[5.786rem] sm:h-[2.375rem] sm:w-[9.995rem] md:h-[2.813rem] md:w-[11.836rem] lg:h-[2.75rem] lg:w-[11.573rem] xl:h-[3.125rem] xl:w-[13.151rem] xxl:h-[3.125rem] xxl:w-[13.151rem]"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Funion-europea.svg?alt=media"
						alt="" />
					<img
						class="hidden object-contain xs:h-[1.375rem] xs:w-[8.658rem] sm:h-[2.375rem] sm:w-[14.955rem] md:h-[2.813rem] md:w-[17.709rem] lg:block lg:h-[2.75rem] lg:w-[7.472rem] xl:h-[3.125rem] xl:w-[8.491rem] xxl:h-[3.125rem] xxl:w-[8.491rem]"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Frtyr.svg?alt=media"
						alt="" />
					<img
						class="object-contain xs:h-[1.375rem] xs:w-[8.658rem] sm:h-[2.375rem] sm:w-[14.955rem] md:h-[2.813rem] md:w-[17.709rem] lg:hidden"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Frtyr-horizontal.svg?alt=media"
						alt="" />
				</div>
				<div
					class="flex flex-row xs:gap-[1.806rem] sm:gap-[1.578rem] md:gap-[4.698rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem]">
					<img
						class="object-contain xs:h-[1.375rem] xs:w-[6.679rem] sm:h-[2.375rem] sm:w-[11.536rem] md:h-[2.813rem] md:w-[13.661rem] lg:h-[2.75rem] lg:w-[13.357rem] xl:h-[3.125rem] xl:w-[15.179rem] xxl:h-[3.125rem] xxl:w-[15.179rem]"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Finnvierte.svg?alt=media"
						alt="" />
					<img
						class="object-contain xs:h-[1.375rem] xs:w-[7.821rem] sm:h-[2.375rem] sm:w-[13.508rem] md:h-[2.813rem] md:w-[15.996rem] lg:h-[2.75rem] lg:w-[15.641rem] xl:h-[3.125rem] xl:w-[17.774rem] xxl:h-[3.438rem] xxl:w-[19.551rem]"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FCDTI2023.svg?alt=media"
						alt="" />
				</div>
			</div>
			<img
				class="object-contain xs:h-[2.813rem] xs:w-[3.319rem] sm:h-[4.875rem] sm:w-[5.754rem] md:h-[5.313rem] md:w-[6.271rem] lg:h-[4.375rem] lg:w-[5.164rem] xl:h-[4.688rem] xl:w-[5.533rem] xxl:h-[4.688rem] xxl:w-[5.533rem]"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fenisa.svg?alt=media"
				alt="" />
		</div>
		<p
			class="rights montserrat text-center xs:mt-[1.313rem] sm:mt-[1.875rem] md:mt-[1.938rem] lg:mt-[0.688rem] xl:mt-[0.375rem] xxl:mt-[0.375rem]">
			{{ 'shared.footer.rightsMessage' | translate }}
		</p>
	</div>
</footer>
