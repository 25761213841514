<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<section
	class="montserrat flex h-full w-full flex-row items-center justify-center font-bold text-VerdeOscuro text-[1.25]">
	@if (numberOfPages > 1) {
		<section
			class="mb-[5.44rem] flex h-[2.75rem] w-[35.4rem] flex-row items-center justify-center xs:mb-[3.94rem] sm:mb-[4.56rem] md:mb-[4.56rem]"
			*ngIf="numberOfPages > 1">
			<img
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-left.svg?alt=media"
				width="28px"
				data-cy="chevron-left"
				[class.chevron-disabled]="disablePrevious"
				(click)="previousPage()"
				class="mr-[2.94rem] hidden cursor-pointer sm:block"
				alt="chevron left" />
			@if (numberOfPages > 7) {
				<div class="flex items-center gap-5">
					@for (pageNumber of getVisiblePageNumbers(); track pageNumber) {
						<div
							class="pagination-button flex h-[2.75rem] w-[2.75rem] items-center justify-center rounded-full"
							[class.cursor-pointer]="pageNumber !== '...'"
							[ngClass]="{
								'hover:border-[0.188rem] hover:border-VerdeOscuro hover:bg-[#FFFFFF40] hover:shadow-[rgba(0,71,81,1)_0px_0px_0.625rem_0.063rem]':
									isPageNumberClickable(pageNumber),
								'border-[0.188rem] border-VerdeOscuro': pageSelected === pageNumber,
							}"
							(click)="isPageNumberClickable(pageNumber) && selectPage(pageNumber)">
							{{ pageNumber }}
						</div>
					}
				</div>
			}
			@if (numberOfPages <= 7) {
				<div class="flex items-center gap-5">
					<div
						*ngFor="let _ of counterPages(); let i = index"
						class="pagination-button flex h-[2.75rem] w-[2.75rem] items-center justify-center rounded-full hover:border-[3px] hover:border-VerdeOscuro hover:bg-[#FFFFFF40] hover:shadow-[rgba(0,71,81,1)_0px_0px_10px_1px]"
						[ngClass]="{
							'border-[3px] border-VerdeOscuro': pageSelected === i,
							'cursor-pointer': true,
						}"
						(click)="selectPage(i)">
						{{ i + 1 }}
					</div>
				</div>
			}
			<img
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-right.svg?alt=media"
				width="28px"
				data-cy="chevron-right"
				[class.chevron-disabled]="disableNext"
				class="ml-[2.94rem] hidden cursor-pointer sm:block"
				(click)="nextPage()"
				alt="chevron right" />
		</section>
	}
</section>
