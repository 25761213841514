import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrl: './pagination.component.sass',
})
export class PaginationComponent {
	@Input() numberOfPages: number = 1;
	@Input() pageSelected: number = 0;
	@Output() pageSelection: EventEmitter<number> = new EventEmitter<number>();

	disableNext: boolean = false;
	disablePrevious: boolean = true;

	selectPage(pageNumber: string | number) {
		this.pageSelected = typeof pageNumber === 'string' ? parseInt(pageNumber, 10) : pageNumber;
		this.updateButtonStatus();
		this.pageSelection.emit(this.pageSelected);
	}

	nextPage() {
		if (this.pageSelected < this.numberOfPages - 1) {
			this.selectPage(this.pageSelected + 1);
		}
	}

	previousPage() {
		if (this.pageSelected > 0) {
			this.selectPage(this.pageSelected - 1);
		}
	}

	counterPages() {
		return new Array(this.numberOfPages);
	}

	updateButtonStatus() {
		this.disablePrevious = this.pageSelected === 1;
		this.disableNext = this.pageSelected === this.numberOfPages;
	}

	getVisiblePageNumbers(): (number | string)[] {
		const visiblePages: (number | string)[] = [];
		const totalVisiblePages = 5;
		let startPage = Math.max(1, this.pageSelected - Math.floor(totalVisiblePages / 2));
		const endPage = Math.min(this.numberOfPages, startPage + totalVisiblePages - 1);

		if (endPage - startPage < totalVisiblePages - 1) {
			startPage = Math.max(1, endPage - totalVisiblePages + 1);
		}

		visiblePages.push(1); // Siempre mostrar la primera página

		if (startPage >= 2) {
			visiblePages.push('...'); // Puntos suspensivos si hay más de 2 páginas de separación
		}

		const middlePages = this.calculateMiddlePages(startPage, endPage);
		visiblePages.push(...middlePages);

		if (endPage < this.numberOfPages - 1) {
			visiblePages.push('...'); // Puntos suspensivos si hay más de 2 páginas de separación
		}

		visiblePages.push(this.numberOfPages); // Siempre mostrar la última página

		return visiblePages;
	}

	calculateMiddlePages(startPage: number, endPage: number): (number | string)[] {
		const middlePages: (number | string)[] = [];
		for (let i = startPage + 1; i < endPage; i++) {
			middlePages.push(i); // Agregar las páginas intermedias
		}
		return middlePages;
	}

	isPageNumberClickable(pageNumber: number | string): boolean {
		return typeof pageNumber === 'number';
	}
}
