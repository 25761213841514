import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonType } from '../general-button/general-button.component';

@Component({
	selector: 'app-cookie-consent-modal',
	templateUrl: './cookie-consent-modal.component.html',
	styleUrl: './cookie-consent-modal.component.sass',
})
export class CookieConsentModalComponent {
	@Output() navigateToCookiesPage: EventEmitter<any> = new EventEmitter();
	@Output() acceptAllCookies: EventEmitter<any> = new EventEmitter();
	@Output() acceptMandatoryCookies: EventEmitter<any> = new EventEmitter();

	buttonType = ButtonType;

	constructor() {}
}
