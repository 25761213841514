import { Component, Input } from '@angular/core';

export enum GeneralCardType {
	MORADO_CLARO = 1,
	CLARO = 2,
	OSCURO = 3,
}

@Component({
	selector: 'app-general-card',
	templateUrl: './general-card.component.html',
	styleUrl: './general-card.component.sass',
})
export class GeneralCardComponent {
	@Input() header: string = '1';
	@Input() headerUrl: string = '';

	@Input() title: string = 'Card Title';
	@Input() text: string = 'Card Text';
	@Input() cardType: GeneralCardType = GeneralCardType.MORADO_CLARO;
	@Input() thereIsImage: boolean = false;
	@Input() greenCircle: boolean = false;

	typeEnum = GeneralCardType;
}
